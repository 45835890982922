import React from 'react';
import styled from 'styled-components';
import { colors } from 'herald-fe-shared';

import Page from '~/layout/LayoutMarketing';
import LargeCTA from '~/components/LargeCTA';
import Opener from '~/components/works-with/Opener';
import BenefitsOfHerald from '~/components/works-with/BenefitsOfHerald';
import HeraldSolution from '~/components/works-with/HeraldSolution';

const Styled = styled.div`
  .container {
    position: relative;
    display: flex;
    justify-content: space-between;
  }
  .container__image {
    flex: 1 1 auto;
  }
  .container__text {
    flex: 0 0 50%;
  }
  .herald-solution .section--gray {
    background: ${colors.WHITE()};
  }
  .collect__logos {
    display: flex;
    margin-top: 1rem;
  }
  .collect__logos__logo {
    width: 1.25rem;
    height: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .collect__logos__logo .gatsby-image-wrapper {
    width: 100%;
  }
  .collect__logos__logo--gdocs {
    width: 1rem;
  }
  .collect__logos__logo--link {
    transition: 250ms all;
  }
  .collect__logos__logo--link:hover {
    transform: scale(1.6);
  }
  @media screen and (max-width: 600px) {
    .collect__logos {
      justify-content: center;
    }
  }
`;

const WorksWithEverything = () => {
  return (
    <Page title="Herald Works With All Your Favorite Apps">
      <Styled>
        <Opener app="everything" />
        <HeraldSolution
          firstSentence={
            <>
              Herald is the best tool to to organize and quantify feedback from
              any channel you use to talk to customers.
            </>
          }
        />
        <BenefitsOfHerald />
        <LargeCTA title={<>Ready to supercharge your team's workflow?</>} />
      </Styled>
    </Page>
  );
};

export default WorksWithEverything;
